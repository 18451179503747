<template>
  <div class="edp-layout" :class="{scrollLayout}">
    <div class="header">
      <div class="left">
        <div class="menu-block">
          <div class="menu-btn">
            <img alt="" :src="$toUrl('menu/menu_buttoon.png')">
          </div>
          <div class="left-menu-block">
            <div class="left-menu">
              <div class="title">企业集成项目管理</div>
              <div class="menu-list">
                <template v-for="(item, key) in leftMenus">
                  <router-link v-if="item.menu_url!=='/dataScreen'" :to="{path: item.menu_url}" class="menu-item"
                               :key="key">
                    <img class="icon" :src="$toUrl(item.icon)">
                    <div class="text">{{ item.menu_name }}</div>
                  </router-link>
                  <router-link v-else :to="{path: item.menu_url}" target="_blank" class="menu-item" :key="key">
                    <img class="icon" :src="$toUrl(item.icon)">
                    <div class="text">{{ item.menu_name }}</div>
                  </router-link>
                </template>
              </div>
            </div>
          </div>
        </div>
        <router-link :to="{path: titlePath}" class="title">{{ title }}</router-link>
        <div class="select-project" v-if="visibleProjectSelect">
          <a-select @blur="blurEvent" style="min-width: 100px;" :dropdownMatchSelectWidth="false" :value="$route.query.prj_oid" @change="onChangePrj" showSearch optionFilterProp="children">
            <a-select-option v-for="item in projectList" :key="item.oid" :value="item.oid">{{item.project_name}}</a-select-option>
          </a-select>
        </div>
        <div class="menus">
          <router-link v-for="(item, key) in topMenusResult" :key="key" :to="item?.to" class="menu-item">
            <template v-if="item.icon">
              <img class="icon normal" :src="$toUrl('menu/' + item.icon + '.png')" alt="">
              <img class="icon active" :src="$toUrl('menu/' + item.icon + '_a.png')" alt="">
            </template>
            {{ item?.title }}
          </router-link>
        </div>
      </div>
      <div class="right">
        <div class="create-btn" v-if="isWorkSpace">
          <div class="btn">
            <a-button type="primary" v-if="!isSupplier" @click="openPrjModal" style="margin-right: 10px;">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              新建项目
            </a-button>
          </div>
          <div class="btn">
            <a-button type="primary" @click="openTaskModal" style="margin-right: 10px;">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              任务项
            </a-button>
          </div>
          <div class="btn">
            <a-button type="primary" @click="openGeneralTaskModal">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              一般事项
            </a-button>
          </div>
        </div>
        <a-dropdown placement="topLeft">
          <a-menu slot="overlay" @click="handleMenu">
            <a-menu-item>
              <router-link :to="{path: '/personalCenter'}" rel="noopener noreferrer">个人中心</router-link>
            </a-menu-item>
            <a-menu-item key="logout">
              <a target="_blank" rel="noopener noreferrer">退出</a>
            </a-menu-item>
          </a-menu>
          <img class="avatar" :src="$toUrl(company_info.picture)" alt="">
        </a-dropdown>
      </div>
    </div>
    <div class="visible-empty"></div>
    <div class="view">
      <div class="sub-menus" v-if="subMenusResult.length > 0">
        <router-link class="menu-item" v-for="(item, key) in subMenusResult" :key="key" :to="item?.to">
          {{ item.title }}
        </router-link>
      </div>
      <div class="view-component">
        <router-view v-if="reloadFlag"></router-view>
      </div>
    </div>
    <create-task-modal @reload="addTaskReload" ref="createTaskModal"></create-task-modal>
    <create-general-task-modal @reload="addTaskReload" ref="createGeneralTaskModal"></create-general-task-modal>
    <create-prj-modal @reload="addProjectReload" ref="createPrjModal"></create-prj-modal>
  </div>
</template>

<script>
import {fetch} from "@/utils/request.js";
import CreateTaskModal from "@/components/createTaskModal.vue";
import CreatePrjModal from "@/components/createPrjModal.vue";
import CreateGeneralTaskModal from "@/components/createGeneralTaskModal.vue";

export default {
  components: {CreateGeneralTaskModal, CreateTaskModal, CreatePrjModal},
  data() {
    return {
      title: "",
      titlePath: "",
      topMenus: [],
      subMenus: [],
      company_info: {},
      leftMenus: [],
      projectList: [],
      old_leftMenus: [
        [
          {icon: require("@/assets/image/layout/menu_workbench.png"), title: "工作台", to: "/workspace"},
          {icon: require("@/assets/image/layout/menu_project.png"), title: "项目管理", to: "/project/list"},
          {
            icon: require("@/assets/image/layout/menu_digital_asset.png"),
            title: "数据资产",
            to: "/digitalAssets/Digital"
          },
          {icon: require("@/assets/image/layout/menu_product.png"), title: "产品管理", to: "/product/productManage"},
        ],
        [
          {icon: require("@/assets/image/layout/menu_settings.png"), title: "配置管理", to: "/settings"},
        ]
      ],
      query: {},
      isWorkSpace: false,
      reloadFlag: true
    }
  },
  computed: {
    visibleProjectSelect() {
      return this.$route.query.prj_oid && this.$route.fullPath.indexOf("/project") === 0
    },
    scrollLayout() {
      return !(this.$route.meta.fill === false)
    },
    topMenusResult() {
      let roleCodes = JSON.parse(localStorage.getItem("company_info")).Roles?.map(item => item.role_code) || []
      return this.topMenus.filter(item => {
        if (item.blackRoles?.length) {
          if (item.blackRoles.find(find => roleCodes.includes(find))) {
            return false
          }
        }
        return (this.$getP().includes(item.code) || !item.code)
      }).map(item => {
        let to = typeof item.to === 'function' ? item.to(this.$route) : item.to
        return {
          ...item,
          to
        }
      })
    },
    subMenusResult() {
      return this.subMenus.map(item => {
        let to = typeof item.to === 'function' ? item.to(this.$route) : item.to
        return {
          ...item,
          to
        }
      })
    },
    isSupplier() {
      let roles = JSON.parse(localStorage.getItem('company_info')).Roles
      return !!roles.find(item => item.role_code === 'supplier')
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        let initTitle = 0
        let initMenus = 0
        let initSubMenus = 0
        this.topMenus = []
        this.subMenus = []
        this.title = ""
        // console.log(this.$route.matched);
        for (let i = this.$route.matched.length - 1; i >= 0; i--) {
          let item = this.$route.matched[i]
          if (initTitle === 0 && item.meta?.title !== undefined) {
            this.title = item.meta.title
            this.titlePath = item.path
            this.isWorkSpace = item.path === '/workspace'
            initTitle = 1
          }
          if (initMenus === 0 && Array.isArray(item.meta?.menus)) {
            this.topMenus = item.meta.menus
            initMenus = 1
          }
          if (initSubMenus === 0 && Array.isArray(item.meta?.submenu) && item.meta?.hideSubmenu !== true) {
            this.subMenus = item.meta.submenu
            console.log('subMenus', item)
            initSubMenus = 1
          }
          if (initTitle + initMenus + initSubMenus === 2) {
            break
          }
        }
      }
    }
  },
  created() {
    this.company_info = this.storage.getItem('company_info')
    this.getMenu()
    this.getProjectList()
  },
  methods: {
    blurEvent() {
      console.log("blur")
    },
    onChangePrj(value) {
      if (this.$route.query.prj_oid !== value) {
        this.$router.push("/project?prj_oid=" + value)
      }
    },
    getProjectList() {
      fetch("post", "/prj/retrieve").then(res => {
        if (res.status === 0) {
          this.projectList = res.data.datas
        }
      })
    },
    getMenu() {
      fetch("post", "account/usermenu").then(res => {
        if (res.status === 0) {
          this.leftMenus = res.data.menus
        }
      })
    },
    jump(e) {
      e.stopPropagation()
    },
    handleMenu({key}) {
      if (key === 'logout') {
        fetch("post", "/account/logout").then(res => {
          if (res.status === 0) {
            localStorage.clear()
            this.$router.push('/login')
            this.$message.success("退出成功！")
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    openTaskModal() {
      this.$refs.createTaskModal.open()
    },
    openGeneralTaskModal() {
      this.$refs.createGeneralTaskModal.open()
    },
    openPrjModal() {
      this.$refs.createPrjModal.open()
    },
    addTaskReload() {
      this.$router.push({path: "/workspace/workitem", query: {issue_status: "0"}}).then(() => {
        this.reload()
      })
    },
    addProjectReload() {
      this.$router.push({path: "/workspace/project", query: {default_status: "0"}}).then(() => {
        this.reload()
      })
    },
    reload() {
      this.reloadFlag = false
      let timer = setTimeout(()=>{
        this.reloadFlag = true
        clearTimeout(timer)
      },100)
    }
  }
}
</script>

<style lang="less" scoped>
@minHeight: 0px;
.edp-layout {
  &.scrollLayout {
    > .view {
      height: calc(100vh - 50px);
      min-height: calc(@minHeight + 50px);

      /deep/ .view-component {
        flex: 1;
        overflow-y: hidden;
        min-height: @minHeight;

        > .app-main-content {
          > .edp-card {
            height: 100%;

            .edp-card-body {
              overflow-y: auto;
              flex: 1;
              display: flex;
              flex-direction: column;
            }
          }
        }

        .scrollY {
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }

  > .visible-empty, > .header {
    height: 50px;
  }

  > .header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;

    > .left {
      display: flex;
      align-items: center;
      padding-left: 15px;
      height: 100%;
      .select-project {
        margin-right: 20px;
      }

      > .vertical-line {
        height: 18px;
        width: 1px;
        background-color: #D4D4D4;
        margin: 0 20px;
      }

      > .menu-block {
        height: 100%;

        &:hover {
          > .left-menu-block {
            display: block;
          }
        }

        > .menu-btn {
          height: 100%;
          padding: 0 10px;
          cursor: pointer;
          display: flex;
          align-items: center;

          img {
            display: block;
            width: 16px;
            height: 14px;
          }
        }

        > .left-menu-block {
          display: none;
          position: fixed;
          left: 0;
          top: 49px;
          padding-top: 14px;

          > .left-menu {
            width: 200px;
            background-color: #313A4C;
            border-radius: 0 8px 8px 0;
            padding-bottom: 20px;

            > .title {
              font-weight: 700;
              color: white;
              font-size: 20px;
              padding: 25px 0 25px 20px;
            }

            > .menu-list {
              .menu-item {
                display: flex;
                align-items: center;
                height: 40px;
                font-size: 14px;
                padding-left: 20px;

                &.router-link-active {
                  background-color: rgba(255, 255, 255, .1);

                  .text {
                    color: white;
                  }
                }

                .text {
                  color: #BEC4C8;
                  font-size: 14px;
                  margin-left: 11px;
                }

                .icon {
                  width: 14px;
                  height: 12px;
                }
              }

              .line {
                margin: 15px 0;
                width: 100%;
                height: 1px;
                background: rgba(255, 255, 255, 0.1);
              }
            }
          }
        }
      }

      > .title {
        font-size: 16px;
        color: var(--primary-color);
        font-weight: 500;
        margin: 0 20px;
      }

      > .menus {
        height: 100%;

        .menu-item {
          display: inline-flex;
          align-items: center;
          height: 100%;
          color: #606060;
          font-size: 15px;
          margin-right: 10px;
          font-weight: 500;
          padding: 0 15px;

          &.router-link-active {
            font-weight: 700;
            background-color: var(--primary-color);
            color: white;

            .icon {
              &.normal {
                display: none;
              }

              &.active {
                display: block;
              }
            }
          }

          .icon {
            height: 15px;
            width: auto;
            margin-right: 5px;

            &.active {
              display: none;
            }
          }
        }
      }

      > .my-prj {
        display: flex;
        align-items: center;

        .label {
          margin-right: 7px;
          font-weight: 400;
          font-size: 14px;
          color: #494949;
        }

        /deep/ .ant-select {
          .ant-select-selection {
            border: none;
            border-radius: 4px 4px 4px 4px;
            background: #F3F3F3;
          }
        }
      }
    }

    > .right {
      padding-right: 20px;
      display: flex;

      .create-btn{
        display: flex;
        margin-right: 20px;
      }

      .avatar {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-color: #2c3e50;
      }
    }
  }

  > .view {
    //padding: 0 20px;
    display: flex;
    flex-direction: column;

    .sub-menus {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      margin-bottom: 10px;
      background: #F8F8F8;
      box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.50);

      .menu-item {
        height: 32px;
        line-height: 32px;
        color: #494949;
        font-size: 14px;
        padding: 0 7px;
        margin-right: 20px;
        font-weight: 500;

        &.router-link-active {
          border-bottom: 2px solid var(--primary-color);
          color: var(--primary-color);
        }
      }
    }
  }
}
</style>
