<template>
  <a-modal
    title="新建项目"
    :visible="visible"
    :confirm-loading="false"
    @ok="handleOk"
    @cancel="visible = false"
    cancelText="取消"
    okText="确定"
    width="1100px"
    class="createPorjectModal"
  >
    <div class="modal-content">
      <div class="modal-content-left">
        <a-form layout="vertical" :form="form" class="edp-form">
          <div class="row-form-item">
            <a-form-item prop="project_name" label="项目名称">
              <a-input placeholder="请输入项目名称" v-decorator="[`project_name`, formRule.project_name]"/>
            </a-form-item>
            <a-form-item prop="copy_project_oid" label="项目编号">
              <a-input placeholder="请输入项目编号" v-decorator="[`project_code`, formRule.project_code]"/>
            </a-form-item>
          </div>
          <a-form-item prop="copy_project_oid" label="选择要复制的项目">
            <a-select placeholder="选择要复制的项目" v-decorator="[`copy_project_oid`]">
              <a-select-option v-for="item in tempList" :key="item.oid" :value="item.oid">
                {{ item.project_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item prop="project_intro" label="项目介绍">
            <a-textarea placeholder="请输入项目介绍" :rows="4" v-decorator="[`project_intro`]"/>
          </a-form-item>
          <attachmentUpload :source-oid="oid" :prj-oid="oid">
            <template slot="title">附件上传</template>
          </attachmentUpload>
        </a-form>
      </div>
      <div class="modal-content-right">
        <a-input class="search-input" v-model="memberSearch" placeholder="搜索姓名、邮箱、公司名称">
          <a-icon slot="prefix" type="search"/>
        </a-input>
        <div class="member-list">
          <div class="member-list-items">
            <a-checkbox-group v-model="checked" :options="searchItems" v-if="searchItems.length" name="project"
                              @change="onChange">
              <template slot="label" slot-scope="row">
                <a-space>
                  <a-avatar :size="20" :src="row.pic"/>
                  <div class="userName a-style-ellipsis">{{ row.title ? row.name + '-' + row.title : row.name }}</div>
                  <div class="mobile a-style-ellipsis">({{ row.mobile }})</div>
                  <div class="companyName a-style-ellipsis">{{ row.company_name }}</div>
                </a-space>
              </template>
            </a-checkbox-group>
            <edp-empty v-else></edp-empty>
          </div>
          <div class="member-list-items-checked">
            <div class="header">
              <span class="checked">已选</span>(共{{ newChecked.length }}个成员)
            </div>
            <div class="list-item" v-for="(item, index) in checkedItems" :key="index">
              <a-space>
                <a-avatar :size="20" :src="item?.pic"/>
                <div class="userName a-style-ellipsis">{{ item?.title ? item?.name + '-' + item?.title : item?.name }}</div>
                <div class="mobile a-style-ellipsis">({{ item?.mobile }})</div>
                <div class="companyName a-style-ellipsis">{{ item?.company_name }}</div>
              </a-space>
              <div class="remove" @click="removeChecked(item)">
                <a-icon type="close"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import attachmentUpload from "@/components/attachmentUpload.vue";
import {fetch} from "@/utils/request";
import {genGuid} from "@/utils/helpFunc";

export default {
  name: "createPrjModal",
  components: {attachmentUpload},
  emits: ['reload'],
  data() {
    return {
      tempList: [],
      visible: false,
      form: this.$form.createForm(this, {name: 'pro_form'}),
      formRule: {
        project_name: {
          rules: [
            {required: true, message: '请输入项目名称',}
          ]
        },
        project_code: {
          rules: [
            {required: true, message: '请输入项目编号',}
          ]
        }
      },
      memberSearch: '',
      checked: [],
      newChecked: [],
      options: [],
      allOptions: [],
      accountList: [],
      company_info: {},
      oid: ''
    }
  },
  computed: {
    checkedItems() {
      return this.newChecked.map(item => {
        return this.allOptions.find(i => item === i.value)
      })
    },
    searchItems() {
      if (this.memberSearch) {
        return this.options.filter((i) => {
          return i.name.indexOf(this.memberSearch) !== -1 || i.email.indexOf(this.memberSearch) !== -1 || i.company_name.indexOf(this.memberSearch) !== -1
        })
      } else {
        return this.options.filter((item) => {
          return this.company_info.company_oid === item.company_oid
        }).filter((item) => {
          return this.company_info.account_oid !== item.oid
        })
      }
    }
  },
  created() {
    this.company_info = this.storage.getItem('company_info')
  },
  mounted() {
    this.getTempList()
    this.getMemberList()
  },
  methods: {
    open() {
      this.visible = true
      this.form.resetFields()
      this.oid = genGuid()
    },
    removeChecked(item) {
      console.log(item);
      this.newChecked = this.newChecked.filter(i => i !== item.value) // 已选中删除
      this.checked = this.checked.filter(i => i !== item.value) // 选中删除
    },
    onChange(checkedList) {
      // console.log(checkedList);
      // console.log(this.searchItems);
      if (checkedList.length > 0) { // 选取有值时
        checkedList.forEach(e1 => {
          if (!this.newChecked.includes(e1)) { // 总选中数组遍历没有的 push进去
            this.newChecked.push(e1)
          }
        })
      }
      this.searchItems.forEach(e1 => { // 遍历搜索出来的所有项
        if (!checkedList.includes(e1.value)) { // 当前选中的有没有搜索出来的值
          console.log(e1.username);
          let index = this.newChecked.indexOf(e1.value)
          if (index !== -1) this.newChecked.splice(index, 1) // 没有就进行删除
        }
      })
      this.checked = this.newChecked
    },
    getTempList() {
      fetch('post', '/prj/retrieve', {
        keywords: '',
        business_status: null,
        orderby: '',
        is_template: '1'
      }).then((res) => {
        if (res.status === 0) {
          this.tempList = res.data.datas
        }
      })
    },
    getMemberList() {
      fetch('post', '/cpyaccount/list', {keywords: '', page_size: 999}).then((res) => {
        if (res.status === 0) {
          let data = res.data.datas
          if (data.length > 0) {
            data.forEach(e => {
              e['value'] = e.oid
            })
            data.sort((a, b) => {
              if (Number(a.seqid) > Number(b.seqid)) {
                return 1
              } else {
                return -1
              }
            })
            this.options = data
            this.allOptions = data
            // console.log(this.options);
          }
        }
      })
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          let url = window.mock ? 'http://127.0.0.1:4523/m1/2234408-0-default/prj/create' : '/prj/create'
          let data = values
          data.member_oids = this.checked
          data.oid = this.oid
          console.log(data);
          fetch('post', url, data).then((res) => {
            if (res.status === 0) {
              this.$message.success('新建成功！')
              this.$emit("reload")
              this.form.resetFields();
              this.checked = []
              this.visible = false;
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
  }
}
</script>


<style scoped lang="less">
.member-list {
  padding-top: 25px;

  .member-list-items {
    font-size: 14px;
    max-height: 175px;
    overflow-y: auto;
    /deep/ .ant-checkbox-group-item {
      display: block;
    }

    /deep/ .ant-space {
      margin-bottom: 5px;
    }

    .userName {
      max-width: 115px;
      font-size: 16px;
      color: #4D4D4D;
    }

    .mobile {
      max-width: 100px;
      color: #A7A7A7;
    }

    .companyName {
      max-width: 115px;
      color: #A7A7A7;
    }

    /deep/ .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px;
    }

  }

  .member-list-items-checked {
    padding-top: 10px;

    .header {
      padding: 10px 0;
      display: flex;
      align-items: center;
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      margin-bottom: 10px;

      .checked {
        font-size: 18px;
        color: #4D4D4D;
        margin-right: 5px;
      }
    }

    .list-item {
      //height: 36px;
      //line-height: 36px;
      overflow: hidden;
      justify-content: space-between;
      display: flex;
      margin-bottom: 5px;

      .userName {
        max-width: 115px;
        font-size: 16px;
        color: #4D4D4D;
      }

      .mobile {
        max-width: 95px;
        color: #A7A7A7;
      }

      .companyName {
        max-width: 115px;
        color: #A7A7A7;
      }

      .remove {
        cursor: pointer;
      }
    }
  }
}

.ant-space-item > .ant-avatar-image {
  vertical-align: text-bottom;
}


.createPorjectModal {
  /deep/ .ant-modal-body {
    padding: 0;
    overflow-y: auto;

    .modal-content {
      display: flex;

      .modal-content-left {
        width: 628px;
        padding: 20px 34px;

        .row-form-item {
          display: flex;
          justify-content: space-between;
          .ant-form-item {
            width: 48%;
          }
        }
        .ant-form-item {
          margin-bottom: 12px;
        }
      }

      .modal-content-right {
        width: 471px;
        border-left: 1px solid rgba(237, 237, 237, 1);
        padding: 20px 34px 20px 33px;
      }
    }
  }
}
</style>
