<template>
  <a-modal v-model="visible" title="新建任务项" width="680px" destroyOnClose class="createTaskModal">
    <a-form-model layout="vertical" ref="form" :model="form" class="edp-form">
      <div class="line-2">
        <a-form-model-item>
          <span slot="label">项目<i class="required">*</i></span>
          <a-select :disabled="disableProject" v-model="form.prjoid" @change="onChangePrj(true)">
            <a-select-option v-for="item in projectList" :key="item.oid" :value="item.oid">
              {{item.project_name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item >
          <span slot="label">标题<i class="required">*</i></span>
          <a-input v-model="form.issue_name"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <span slot="label">负责人<i class="required">*</i></span>
          <a-select showSearch optionFilterProp="children" v-model="form.issue_owner" :disabled="!form.prjoid">
            <a-select-option v-for="item in accountList" :value="item.user_oid" :key="item.user_oid">
              {{ item.title ? item.name + '-' + item.title : item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item >
          <span slot="label">优先级<i class="required">*</i></span>
          <a-select v-model="form.issue_level">
            <a-select-option v-for="item in cat.issues_level" :key="item.cat_code" :value="item.cat_code">
              {{ item.cat_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item >
          <span slot="label">任务编码</span>
          <a-input v-model="form.issue_code"></a-input>
        </a-form-model-item>
      </div>
      <a-form-model-item label="标签" prop="issue_tag">
        <a-checkbox-group v-model="form.issue_tag" :options="cat.issue_tags.map(item => item.cat_name)">
        </a-checkbox-group>
      </a-form-model-item>
<!--      <a-form-model-item >-->
<!--        <span slot="label">任务标签</span>-->
<!--        <a-checkbox-group v-model="form.issue_tag" :options="cat.issue_tags.map(item => item.cat_name)"/>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="描述" prop="issue_desc">
        <a-textarea v-model="form.issue_desc"></a-textarea>
      </a-form-model-item>
      <div class="line-2">
        <a-form-model-item prop="phase_oid">
          <span slot="label">所属阶段<i class="required">*</i></span>
          <a-select v-model="form.phase_oid" style="width: 100%">
            <a-select-option v-for="item in phaseList" :key="item.oid" :value="item.oid">
              {{item.phase_name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
<!--        <a-form-model-item label="所属需求" prop="req_oid">-->
<!--          <a-select v-model="form.req_oid">-->
<!--            <a-select-option v-for="item in demandList" :key="item.oid" :value="item.oid">-->
<!--              {{ item.req_name }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="计划开始" prop="schedule_start_date">
          <a-date-picker format="YYYY/MM/DD" valueFormat="YYYY/MM/DD" v-model="form.schedule_start_date" style="width: 100%" />
        </a-form-model-item>
        <a-form-model-item label="计划结束" prop="schedule_end_date">
          <a-date-picker format="YYYY/MM/DD" valueFormat="YYYY/MM/DD" v-model="form.schedule_end_date" style="width: 100%;" />
        </a-form-model-item>
        <a-form-model-item label="计划收入" prop="schedule_income">
          <a-input v-model="form.schedule_income"></a-input>
        </a-form-model-item>
        <a-form-model-item label="计划支出" prop="schedule_outcome">
          <a-input v-model="form.schedule_outcome"></a-input>
        </a-form-model-item>
        <a-form-model-item label="所属产品" prop="expenditure">
          <a-select v-model="form.product_oid" :disabled="!form.prjoid">
            <a-select-option v-for="item in productList" :key="item.oid" :value="item.oid">{{item.product_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="协同人">
          <a-select showSearch optionFilterProp="children" mode="multiple" v-model="form.issue_assist">
            <a-select-option v-for="item in accountList" :value="item.user_oid" :key="item.user_oid">
              {{ item.title ? item.name + '-' + item.title : item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="预估工时">
          <a-input v-model="form.schedule_worktime" addon-after="小时"></a-input>
        </a-form-model-item>
      </div>
    </a-form-model>
    <attachment-upload :prj-oid="form.prjoid" :sourceOid="form.oid" source-type="4" @setOid="oid => form.oid = oid" style="margin-top: 30px;"
                       title="附件上传"></attachment-upload>
    <template #footer>
      <div class="modal-footer">
        <a-checkbox v-model="createNext">
          继续创建下一个
        </a-checkbox>
        <a-space>
          <a-button @click="visible = false">
            取消
          </a-button>
          <a-button type="primary" @click="save">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
import AttachmentUpload from "@/components/attachmentUpload.vue";
import {fetch} from "@/utils/request.js";
import catMixin from "@/utils/mixins/catMixin.js";
import dayjs from "dayjs";

export default {
  name: "createTaskModal",
  components: { AttachmentUpload },
  mixins: [catMixin],
  emits: ['reload'],
  data() {
    return {
      createNext: false,
      cat: {
        issues_level: [],
        issue_tags: []
        // issue_tags: []
      },
      visible: false,
      form: {
        oid: "",
        prjoid: "",
        phase_oid: "",
        req_oid: "",
        issue_name: "",
        issue_status: "",
        issue_level: "",
        issue_owner: "",
        issue_assist: [],
        issue_type: "T",
        issue_desc: "",
        issue_tag: [],
        complete_percent: "",
        has_delivery: "",
        route_level: "0",
        route_map: "",
        product_oid: "",
        schedule_start_date: "",
        schedule_end_date: "",
        actual_start_date: "",
        actual_end_date: "",
        schedule_income: "",
        schedule_outcome: "",
        actual_income: "",
        actual_outcome: "",
        deadline: "",
        total_deliverycount: "",
        complete_deliverycount: "",
        schedule_worktime: ''
      },
      relationPerson: [
        { id: 1, name: "姓名1", phone: "18800011122", role: "管理员", edit: false }
      ],
      accountList: [],
      demandList: [],
      backups: {},
      preFill: {},
      projectList: [],
      phaseList: [],
      productList: [],
      disableProject: false,
    }
  },
  watch: {
    "form.schedule_start_date": {
      handler(val, pre) {
        if (val) {
          let formatTemplate = "YYYY/MM/DD"
          if (this.form.schedule_end_date && pre) {
            let start = dayjs(pre, formatTemplate)
            let end = dayjs(this.form.schedule_end_date, formatTemplate)
            let duration = dayjs.duration(end.diff(start))
            console.log(val, pre, this.form.schedule_end_date, duration.asDays())
            this.form.schedule_end_date = dayjs(val, formatTemplate).add(duration.asDays(), "day").format(formatTemplate)
            console.log(this.form.schedule_end_date)
          } else {
            this.form.schedule_end_date = dayjs(val, formatTemplate).add(3, "day").format(formatTemplate)
          }
        }
      }
    }
  },
  created() {

    this.backups = JSON.parse(JSON.stringify(this.form))
  },
  methods: {
    onChangePrj(setEmpty = false) {
      if (!this.form.prjoid) return;
      if (setEmpty) {
        this.form.issue_owner = ""
        this.form.phase_oid = ""
        this.form.product_oid = ""
      }
      console.log(this.form.prjoid);
      // this.getDemand()
      this.getAccount()
      this.getPhaseList()
      this.getProductList()
    },
    getProductList() {
      fetch("post", "prj/product/retrieve/byprj", {
        prjoid: this.form.prjoid,
        page_size: 999
      }).then(res => {
        if (res.status === 0) {
          this.productList = res.data.datas
        }
      })
    },
    getProjectList() {
      fetch("post", "/prj/issue/getProject").then(res => {
        if (res.status === 0) {
          this.projectList = res.data.map(item => {
            return {
              oid: item.prjoid,
              project_name: item.project_name
            }
          })
        }
      })
    },
    getPhaseList() {
      fetch("post", "/prj/phase/retrieve", {
        page_size: 999,
        prjoid: this.form.prjoid
      }).then(res => {
        if (res.status === 0) {
          this.phaseList = res.data.datas
          if (res.data.datas.length && !this.form.phase_oid) {
            this.form.phase_oid = res.data.datas[0].oid
          }
        }
      })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    save() {
      if (!this.form.prjoid) {
        return this.$message.error("请选择项目")
      }
      if (!this.form.issue_name) {
        return this.$message.error("请输入标题")
      }
      if (!this.form.issue_owner) {
        return this.$message.error("请选择负责人")
      }
      if (!this.form.issue_level) {
        return this.$message.error("请选择优先级")
      }
      if (!this.form.phase_oid) {
        return this.$message.error("请选择所属阶段")
      }
      fetch("post", "/prj/issue/create", {
        ...this.form,
        issue_tag: this.form.issue_tag.join(","),
        issue_assist: this.form.issue_assist.join("/"),
        schedule_worktime: this.form.schedule_worktime ? this.form.schedule_worktime * 60 : ''
      }).then(res => {
        if (res.status === 0) {
          this.$message.success("新增成功")
          this.$emit("reload", {
            ...res.data,
            issue_assist_name: this.form.issue_assist.map(item => {
              return this.accountList.find(i => i.user_oid === item).name
            }),
            project_name: this.projectList.find(item => item.oid === res.data.prjoid)?.project_name,
            phase_name: this.phaseList.find(item => item.oid === res.data.phase_oid)?.phase_name,
            issue_level_name: this.cat.issues_level.find(item => item.cat_code === res.data.issue_level)?.cat_name
          })
          if (this.createNext) {
            this.open(this.preFill)
            this.createNext = true
          } else {
            this.visible = false
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getDemand() {
      fetch("post", "/prj/requirement/retrieve", {
        prjoid: this.form.prjoid,
        page_size: 999
      }).then(res => {
        if (res.status === 0) {
          this.demandList = res.data.datas
        }
      })
    },
    getAccount() {
      fetch("post", "/prj/userlist/list", { prjoid: this.form.prjoid }).then(res => {
        if (res.status === 0) {
          this.accountList = res.data
          let info = JSON.parse(localStorage.getItem("company_info"))
          if (this.accountList.find(item => item.user_oid === info.account_oid)) {
            this.form.issue_owner = info.account_oid
          }
        }
      })
    },
    open(fill = {}) {
      this.getProjectList()
      this.disableProject = false
      this.form = {
        ...this.backups,
        ...fill
      }
      this.preFill = { ...fill }
      this.createNext = false
      this.visible = true

      if (this.form.prjoid) {
        this.onChangePrj(false)
      }

      this.form.issue_level = "100"
      this.form.schedule_start_date = dayjs().format("YYYY/MM/DD")
    },
    addPerson() {
      this.form.issue_assist.push({
        id: this.relationPerson.length + 1,
        name: "",
        phone: "",
        role: "",
        edit: true
      })
    },
    submit() {
      this.$refs.form.validate()
    },
    deletePerson(key) {
      this.relationPerson.splice(key, 1)
    }
  }
}
</script>

<style lang="less" scoped>

.line {
  height: 23px;
  background: #F8F8F8;
  font-size: 12px;
  padding-left: 24px;
  position: relative;
  left: -24px;
  width: 665px;
}

.createTaskModal {
  /deep/ .edp-form .ant-form-item-label label {
    font-weight: 500;
    font-size: 16px;
    color: #303030;
  }
  /deep/ .ant-table .ant-btn {
    padding: 0;
  }
  /deep/ .ant-modal-body {
    height: 500px;
    overflow-y: auto;

    .ant-form-item {
      margin-bottom: 12px;
    }
  }

  .label {
    font-size: 16px;
    color: #303030;
    margin-bottom: 10px;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .required{
    color: #f5222d;
    font-size: 14px;
    font-style: normal;
    font-family: SimSun, sans-serif;
  }
}

.relation {
  margin-bottom: 30px;

  .relation-head {
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CACACA;

    .relation-name {
      font-size: 14px;
      color: #303030;
    }

    .relation-action {
      margin-left: auto;
      font-size: 14px;
      display: flex;
      font-weight: 500;
      align-items: center;
      cursor: pointer;

      .anticon {
        margin-right: 5px;
        font-size: 20px;
      }
    }
  }

  .relation-td {
    display: flex;
    align-items: center;

    > img {
      height: 16px;
      margin-right: 5px;
    }
  }
}
</style>
